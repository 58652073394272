import React from "react";

interface IconWindProps extends React.SVGProps<SVGSVGElement> {
  beaufort?: number;
}
const IconWind: React.FC<IconWindProps> = ({
  beaufort,
  height = 60,
  width = 60,
  ...props
}) => {
  const numberPath = [
    "M406 334.2q-8.2 9.8-22 9.8t-21.8-9.8q-8.1-9.7-8.1-26.4 0-16.5 8-26.1 8-9.7 22-9.7 13.7 0 21.8 9.7t8 26.1q.1 16.6-8 26.4Zm-22-4.2q12.6 0 12.6-22T384 286q-12.6 0-12.6 22t12.7 22Z",
    "M402.4 344h-19v-45h-17.8v-12.6h3q7.8 0 12.4-3.4 4.2-3.1 5.5-10.2l.2-.8h15.6Z",
    "M411.9 344h-55.7q0-9.4 4-15.7 4-6.1 14.8-13.5l11-7.8a22.4 22.4 0 005.9-5.5 10.2 10.2 0 001.7-5.8 8.7 8.7 0 00-2.6-6.7 9.8 9.8 0 00-7-2.4q-10 0-10 11.8v1.5h-17v-2.2q0-12.1 7.2-19t20-6.8q12.5 0 19.8 6t7.4 16.7a22 22 0 01-3.5 12.2q-3.5 5.5-11.2 10.4l-12.1 7.7c-2.3 1.5-3.6 2.8-3.8 3.9h31Z",
    "M397.5 305.2a17.8 17.8 0 0110.8 5.7 16.4 16.4 0 013.8 11.2 20 20 0 01-7.2 15.7q-7.3 6.2-20.3 6.2-13.6 0-21-6.7t-7.8-18.6h16.5q.6 11.7 11.7 11.7a11.6 11.6 0 007.7-2.4 8.1 8.1 0 002.8-6.5 7.5 7.5 0 00-3-6.5 14 14 0 00-8.4-2.2h-4.9v-12h5.2c3 0 5.3-.6 7-2a7 7 0 002.4-5.7 7.2 7.2 0 00-2.4-5.8c-1.7-1.4-4-2-7-2s-5.2.7-6.8 2.1-2.4 3.9-2.7 7.2H358q1-22.6 26-22.6 11.8 0 18.5 5.2a16.1 16.1 0 016.6 13.3 14.9 14.9 0 01-3 9.3 15.2 15.2 0 01-8.6 5.4Z",
    "M404.2 272v42.5l10.5-.3v14.3l-10.6-.3V344h-17.6v-15.8l-33.2.3V312l32.5-40.1Zm-36.6 42.5h19v-22.8h-.3Z",
    "M383.8 344q-12 0-19.7-5.5t-9-15.7l17.6-1.4a9.9 9.9 0 003.8 6 12 12 0 007.2 2.2 11.6 11.6 0 008.5-3 10.5 10.5 0 003-8 10.4 10.4 0 00-3-7.8 11.6 11.6 0 00-8.5-3 12.6 12.6 0 00-9.7 4.3l-16.6-1.5 6.4-38.6H408v14.7h-30.9l-2.3 12 .3.2q5.6-4 14.2-4 10.7 0 17 6.2t6.6 17.1q0 11.8-7.8 18.8t-21.3 7Z",
    "M388.5 294.8q11 0 17.7 6.5t6.8 17.3q0 11.3-7.7 18.4t-20.2 7q-12.6 0-20.6-7.7-9.5-9-9.5-27.7 0-17.5 7.6-27t22.2-9.6q10.1 0 16.8 4.6a22.3 22.3 0 019 13l-16.6 1q-2.1-5.6-9.3-5.6-6 0-9 4t-3.7 13q5.8-7.2 16.5-7.2Zm-12.7 31.6a11.5 11.5 0 008.8 3.7q5.4 0 8.3-3.2a11.9 11.9 0 002.9-8 10.6 10.6 0 00-3.2-8 11.2 11.2 0 00-8.2-3.1 11 11 0 00-8 3 12 12 0 00-3.4 8.2 12.3 12.3 0 002.8 7.4Z",
    "M387.3 344h-20q0-13.7 6.5-29a88.4 88.4 0 0117.9-26.7h-35.5V272h55.6v14.7q-12.3 13.1-18.2 26.8a81.8 81.8 0 00-6.3 30.5Z",
    "M405.4 338.1q-7.9 5.9-21.4 5.9t-21.4-5.9a19 19 0 01-7.9-16 16.2 16.2 0 013.5-10.7 17.7 17.7 0 019.9-5.9v-.2a14.8 14.8 0 01-7.5-5.6 15.3 15.3 0 01-2.7-9 16 16 0 016.8-13.7q7-5 19.3-5t19.3 5a16 16 0 017 13.7 15.4 15.4 0 01-2.8 9.1 14.3 14.3 0 01-7.4 5.5v.3a18.1 18.1 0 019.8 5.9 16.2 16.2 0 013.5 10.6 19 19 0 01-8 16Zm-21.3-7q5.6 0 9-2.5a8.5 8.5 0 003.2-7.1 8.5 8.5 0 00-3.2-7.1c-2.2-1.7-5.2-2.5-9-2.5s-7 .8-9.2 2.5a8.5 8.5 0 00-3.2 7 8.4 8.4 0 003.3 7.2 15 15 0 009 2.5Zm0-30.3q5 0 7.8-2.3a7.4 7.4 0 002.9-6.1 7 7 0 00-2.8-6 13 13 0 00-8-2q-10.7 0-10.7 8a7.4 7.4 0 002.8 6.2q2.8 2.1 8 2.1Z",
    "M403.4 279.7q9.7 9 9.7 27.6 0 17.5-7.7 27.1t-22.3 9.6q-10.1 0-16.8-4.6a21.9 21.9 0 01-9-12.8l16.6-1.1q2.1 5.5 9.3 5.5 5.9 0 9-4t3.8-13q-6 7.1-16.6 7.1-10.9 0-17.6-6.4t-6.9-17.3q0-11.2 7.8-18.4t20-7q12.7 0 20.7 7.7Zm-20 28.5a11 11 0 008.2-3 12.2 12.2 0 003.3-8.3 11.6 11.6 0 00-2.7-7.3 11.2 11.2 0 00-9-3.7c-3.5 0-6.2 1-8.2 3.1a11.3 11.3 0 00-2.9 8 10.9 10.9 0 003.1 8.2 11.3 11.3 0 008.3 3Z",
    "M366.4 344h-19v-45h-17.7v-12.6h3q7.8 0 12.3-3.4 4.3-3.1 5.6-10.2l.2-.8h15.6Zm63.9-9.8q-8.2 9.8-21.9 9.8t-21.9-9.8q-8.1-9.7-8.1-26.4 0-16.5 8.1-26.1 8-9.7 21.9-9.7 13.7 0 21.9 9.7t8 26.1q0 16.6-8 26.4Zm-21.9-4.2q12.6 0 12.6-22t-12.6-22q-12.7 0-12.7 22t12.7 22Z",
  ];
  // normalize beaufort
  beaufort = beaufort >= 0 && beaufort <= 10 ? beaufort : 0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      height={height}
      width={width}>
      <defs>
        <linearGradient
          id="a"
          x1="138.5"
          x2="224.2"
          y1="5.1"
          y2="153.5"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#d4d7dd" />
          <stop offset=".5" stopColor="#d4d7dd" />
          <stop offset="1" stopColor="#bec1c6" />
        </linearGradient>
        <linearGradient
          xlinkHref="#a"
          id="b"
          x1="77.7"
          x2="169"
          y1="96.2"
          y2="254.4"
        />
        <symbol id="c" viewBox="0 0 348 240">
          <path
            fill="none"
            stroke="url(#a)"
            strokeLinecap="round"
            strokeDasharray={beaufort ? "148" : null}
            strokeMiterlimit="10"
            strokeWidth="24"
            d="M267.2 24.3A40 40 0 11296 92H12">
            {beaufort ? (
              <animate
                attributeName="stroke-dashoffset"
                dur="6s"
                repeatCount="indefinite"
                values={`0; ${beaufort ? 1184 + 296 * beaufort : 0}`}
              />
            ) : null}
          </path>
          <path
            fill="none"
            stroke="url(#b)"
            strokeDasharray={beaufort ? "110" : null}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="24"
            d="M151.2 215.7A40 40 0 10180 148H12">
            {beaufort ? (
              <animate
                attributeName="stroke-dashoffset"
                dur="6s"
                repeatCount="indefinite"
                values={`0; ${beaufort ? 220 * (1 + beaufort) : 0}`}
              />
            ) : null}
          </path>
        </symbol>
      </defs>
      <use
        xlinkHref="#c"
        width="348"
        height="240"
        transform="translate(82 136)"
      />
      <path fill="#374251" d={numberPath[beaufort]} />
    </svg>
  );
};

export default IconWind;
