import { GenIcon } from "react-icons";

export const IconPickup = (props: any) =>
  GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 72 72" },
    child: [
      {
        tag: "path",
        attr: {
          d: "M33.1,47.9c-.1-.6-.4-1.2-.8-1.6l-6-8.1-3.4,2.6v9.3c0,0,1,1.4,1,1.4l4.4,15.9c.7,2.4,2.9,4.1,5.3,3.9,3.1-.3,5-3.3,4.3-6.2l-4.7-17ZM48.5,42.8c1.3-1.9.9-4.4-1-5.8l-6.1-4.4c.1-7.5-.2-8.8-.4-9.3-.2-.8-1.2-3.7-3-6.1l-2-2.7c-1-1.4-2.7-2.4-4.4-2.6s-3.6.4-4.9,1.6l-14.9,12.7c-1.1,1-1.8,2.3-1.8,3.7l-.2,18.9-6.3,15.5c-1,2.5.1,5.4,2.7,6.4s5.4-.2,6.4-2.7l6.7-16.4c.2-.6.4-1.2.4-1.8v-10.8c.1,0,13.6-10.2,13.6-10.2,0,1.7,0,3.8,0,5.6,0,1.3.6,2.7,1.7,3.5l7.9,5.7c.3.2.6.4.9.4,1.6.8,3.7.3,4.8-1.3ZM38.1,7.7c1.1-4.8,5.9-7.8,10.7-6.6s7.8,5.9,6.6,10.7c-1.1,4.8-5.9,7.8-10.7,6.6s-7.8-5.9-6.6-10.7ZM68.4,68.4c0,1.5-2.9,2.7-13.7,2.7s-13.7-1.4-13.7-2.7c0-2.9,6.1-4.1,13.7-4.1s13.6,1.4,13.7,4.1Z",
        },
        child: [],
      },
    ],
  })(props);
