import { GenIcon, IconBaseProps } from "react-icons";

export type IconStatModifierKey =
  | "check"
  | "defense"
  | "distance"
  | "divide"
  | "down"
  | "forced"
  | "number"
  | "offense"
  | "percent"
  | "unforced"
  | "up";

export type IconStatModifierLocation = "top" | "bottom";

const path: Record<IconStatModifierKey, string> = {
  percent:
    "M23.2,12.8c0,5.7-4.7,10.4-10.4,10.4S2.4,18.6,2.4,12.8,7.1,2.4,12.8,2.4s10.4,4.7,10.4,10.4ZM47.2,36.8c-5.7,0-10.4,4.7-10.4,10.4s4.7,10.4,10.4,10.4,10.4-4.7,10.4-10.4-4.7-10.4-10.4-10.4ZM13.2,57.4L57.4,13.2c2.9-2.9,2.9-7.7,0-10.6-2.9-2.9-7.7-2.9-10.6,0L2.6,46.8c-2.9,2.9-2.9,7.7,0,10.6,1.5,1.5,3.4,2.2,5.3,2.2s3.8-.7,5.3-2.2Z",
  check:
    "M30,1.2C14.1,1.2,1.2,14.1,1.2,30s12.9,28.8,28.8,28.8,28.8-12.9,28.8-28.8S45.9,1.2,30,1.2ZM45,25.6l-16,16c-1,1-2.3,1.5-3.6,1.5s-2.6-.5-3.6-1.5l-6.9-6.9c-2-2-2-5.2,0-7.2,2-2,5.2-2,7.2,0l3.3,3.3,12.4-12.4c2-2,5.2-2,7.2,0,2,2,2,5.2,0,7.2Z",
  number:
    "M52.1,34h-6.4l.7-8h5.7c3.3,0,6-2.7,6-6s-2.7-6-6-6h-4.6l.5-5.6c.3-3.3-2.1-6.2-5.4-6.5-3.3-.3-6.2,2.1-6.5,5.4l-.6,6.6h-8l.5-5.6c.3-3.3-2.1-6.2-5.4-6.5-3.3-.3-6.2,2.1-6.5,5.4l-.6,6.6h-7.5c-3.3,0-6,2.7-6,6s2.7,6,6,6h6.4l-.7,8h-5.7c-3.3,0-6,2.7-6,6s2.7,6,6,6h4.6l-.5,5.6c-.3,3.3,2.1,6.2,5.4,6.5.2,0,.4,0,.5,0,3.1,0,5.7-2.3,6-5.5l.6-6.6h8l-.5,5.6c-.3,3.3,2.1,6.2,5.4,6.5.2,0,.4,0,.5,0,3.1,0,5.7-2.3,6-5.5l.6-6.6h7.5c3.3,0,6-2.7,6-6s-2.7-6-6-6ZM25.7,34l.7-8h8l-.7,8h-8Z",
  distance:
    "M38.7,39.5l-3.8,3.9v-23.6c0,0,0-.1,0-.2v-3.1l3.9,3.9c1.9,1.9,5,1.9,6.9,0,1.9-1.9,1.9-5,0-6.9L33.4,1.4c-1.9-1.9-5-1.9-6.9,0l-12.1,12.1c-1.9,1.9-1.9,5,0,6.9,1.9,1.9,5,1.9,6.9,0l3.8-3.9v23.6c0,0,0,.1,0,.2v3.1l-3.9-3.9c-1.9-1.9-5-1.9-6.9,0-1.9,1.9-1.9,5,0,6.9l12.1,12.1c1.9,1.9,5,1.9,6.9,0l12.1-12.1c1.9-1.9,1.9-5,0-6.9-1.9-1.9-5-1.9-6.9,0Z",
  divide:
    "M24.3,17.8c1.8,1.8,1.8,4.7,0,6.5-.9.9-2.1,1.3-3.2,1.3s-2.3-.4-3.2-1.3l-4.7-4.7-4.7,4.7c-.9.9-2.1,1.3-3.2,1.3s-2.3-.4-3.2-1.3c-1.8-1.8-1.8-4.7,0-6.5l4.7-4.7L1.9,8.4C.2,6.6.2,3.7,1.9,1.9,3.7.2,6.6.2,8.4,1.9l4.7,4.7,4.7-4.7c1.8-1.8,4.7-1.8,6.5,0,1.8,1.8,1.8,4.7,0,6.5l-4.7,4.7,4.7,4.7ZM58,35c-1.8-1.8-4.8-1.8-6.7,0l-4.8,4.8-4-4c-1.8-1.8-4.8-1.8-6.7,0-1.8,1.8-1.8,4.8,0,6.7l6,6v6.2c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7v-6.2l6.8-6.8c1.8-1.8,1.8-4.8,0-6.7ZM13.2,57.4L57.4,13.2c2.9-2.9,2.9-7.7,0-10.6-2.9-2.9-7.7-2.9-10.6,0L2.6,46.8c-2.9,2.9-2.9,7.7,0,10.6,1.5,1.5,3.4,2.2,5.3,2.2s3.8-.7,5.3-2.2Z",
  forced:
    "M35.5,4.4c0-2-1.6-3.7-3.7-3.7s-3.7,1.6-3.7,3.7v23.8c0,1-.8,1.8-1.8,1.8s-1.8-.8-1.8-1.8V8c0-2-1.6-3.7-3.7-3.7s-3.7,1.6-3.7,3.7v31.1c0,.2,0,.4,0,.5l-6.9-6.6c-1.8-1.7-4.7-1.7-6.5.2s-1.7,4.7.2,6.5l12.9,12.2c4.9,4.7,11.5,7.3,18.3,7.3h2.2c11.1,0,20.1-9,20.1-20.1V15.4c0-2-1.6-3.7-3.7-3.7s-3.7,1.6-3.7,3.7v12.8c0,1-.8,1.8-1.8,1.8s-1.8-.8-1.8-1.8V8c0-2-1.6-3.7-3.7-3.7s-3.7,1.6-3.7,3.7v20.1c0,1-.8,1.8-1.8,1.8s-1.8-.8-1.8-1.8V4.4Z",
  unforced:
    "M14.4,32.4c-1.9-1.9-1.9-5,0-6.9,1.9-1.9,5-1.9,6.9,0l3.9,3.9V5.9c0-2.7,2.2-4.9,4.9-4.9s4.9,2.2,4.9,4.9v23.6l3.8-3.9c1.9-1.9,5-1.9,6.9,0,1.9,1.9,1.9,5,0,6.9l-12.1,12.1c-1.9,1.9-5,1.9-6.9,0l-12.1-12.1ZM54.6,49.4H5.4c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9h49.2c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9Z",
  offense:
    "M.9,30.3C.9,13.4,13.1,1.3,30,1.3s29.1,12,29.1,28.9-12.2,29-29.1,29S.9,47.2.9,30.3ZM42.2,30.3c0-8.3-4.9-13.8-12.2-13.8s-12.2,5.2-12.2,13.7,4.9,13.6,12.2,13.6,12.2-5.3,12.2-13.5Z",
  defense:
    "M4.3,12.1c0-6.3,3.8-9.6,8.9-9.6h14.7c18.2,0,29.3,10.5,29.3,27.5s-11.5,27.9-29.7,27.9h-13.6c-5.4,0-9.6-4.2-9.6-9.4V12.1ZM40.2,30c0-8.3-5.1-12.9-12.9-12.9h-5.9v26.3h5.7c7.9,0,13.1-4.9,13.1-13.4Z",
  up: "M25.9,3.2L6.7,22.4c-1.7,1.7-2.2,4.2-1.3,6.3l.2.4c1,1.9,3,3.2,5.2,3.2h7.5v20.4c0,3.2,2.6,5.8,5.8,5.8h12.1c3-.2,5.4-2.8,5.4-5.8v-20.4s7.5,0,7.5,0c3.2,0,5.8-2.6,5.8-5.8,0-1.5-.6-3-1.7-4.1L34.1,3.2c-2.3-2.3-6-2.3-8.2,0h0Z",
  down: "M24.2,1.5h-.4c-3,.2-5.4,2.8-5.4,5.8v20.4h-7.5c-3.2,0-5.8,2.6-5.8,5.8,0,1.5.6,3,1.7,4.1l19.2,19.2c2.3,2.3,6,2.3,8.2,0l19.2-19.2c1.7-1.7,2.2-4.2,1.3-6.3l-.2-.4c-1-1.9-3-3.2-5.2-3.2h-7.5V7.3c0-3.2-2.6-5.8-5.8-5.8h-11.6,0Z",
};

interface IconStatModifierProps extends IconBaseProps {
  icon: IconStatModifierKey;
}

export const IconStatModifier = (props: IconStatModifierProps) =>
  GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 60 60" },
    child: [
      {
        tag: "path",
        attr: {
          d: path[props.icon],
        },
        child: [],
      },
    ],
  })(props);
