import { GenIcon, IconBaseProps } from "react-icons";

interface IconOffenseDefenseSquareProps extends IconBaseProps {
  offense: boolean;
  filled: boolean;
}

export const IconOffenseDefenseSquare = (
  props: IconOffenseDefenseSquareProps
) => {
  const { offense, filled, ...remaining } = props;
  const path: string =
    offense ?? true
      ? filled ?? true
        ? "M10.52,0H1.52C.69,0,.02.67.02,1.5v9c0,.83.67,1.5,1.5,1.5h9c.3,0,.58-.09.82-.25-.22-.37-.35-.8-.35-1.25V1.5c0-.46.13-.88.35-1.25-.24-.15-.52-.25-.82-.25h0ZM8.2,8.39c-.65.62-1.57.95-2.52.95s-1.87-.33-2.52-.95c-.68-.65-1.01-1.54-1.01-2.39s.33-1.76,1.01-2.4c.65-.62,1.57-.95,2.52-.95s1.87.33,2.52.95c.68.64,1.01,1.55,1.01,2.4s-.33,1.74-1.01,2.39ZM4.38,4.78c-.35.34-.51.81-.51,1.26,0,.57.27.96.51,1.19.31.3.74.51,1.3.51s.99-.2,1.29-.5c.3-.3.52-.74.52-1.23s-.22-.94-.52-1.23c-.36-.34-.84-.51-1.29-.51s-.95.16-1.3.51Z"
        : "M10.03,1c-.03.17-.04.33-.04.5v9c0,.17.01.33.04.5H1.52c-.28,0-.5-.22-.5-.5V1.5c0-.28.22-.5.5-.5h8.51M10.52,0H1.52C.69,0,.02.67.02,1.5v9c0,.83.67,1.5,1.5,1.5h9c.3,0,.58-.09.82-.25-.22-.37-.35-.8-.35-1.25V1.5c0-.46.13-.88.35-1.25-.24-.15-.52-.25-.82-.25h0ZM8.2,8.39c-.65.62-1.57.95-2.52.95s-1.87-.33-2.52-.95c-.68-.65-1.01-1.54-1.01-2.39s.33-1.76,1.01-2.4c.65-.62,1.57-.95,2.52-.95s1.87.33,2.52.95c.68.64,1.01,1.55,1.01,2.4s-.33,1.74-1.01,2.39ZM4.38,4.78c-.35.34-.51.81-.51,1.26,0,.57.27.96.51,1.19.31.3.74.51,1.3.51s.99-.2,1.29-.5c.3-.3.52-.74.52-1.23s-.22-.94-.52-1.23c-.36-.34-.84-.51-1.29-.51s-.95.16-1.3.51Z"
      : filled ?? true
      ? "M10.52,0H1.52C.69,0,.02.67.02,1.5v9c0,.83.67,1.5,1.5,1.5h9c.3,0,.58-.09.82-.25-.22-.37-.35-.8-.35-1.25V1.5c0-.46.13-.88.35-1.25-.24-.15-.52-.25-.82-.25h0ZM2.87,9.2V2.8h2.18c.64,0,1.72.09,2.54.88.75.72.89,1.64.89,2.33,0,.84-.21,1.59-.83,2.22-.51.51-1.36.97-2.46.97h-2.32ZM4.53,7.79h.34c.4,0,.97-.04,1.4-.46.21-.2.49-.6.49-1.32,0-.6-.18-1.1-.5-1.4-.39-.36-.91-.4-1.32-.4h-.4v3.58Z"
      : "M10.03,1c-.03.17-.04.33-.04.5v9c0,.17.01.33.04.5H1.52c-.28,0-.5-.22-.5-.5V1.5c0-.28.22-.5.5-.5h8.51M10.52,0H1.52C.69,0,.02.67.02,1.5v9c0,.83.67,1.5,1.5,1.5h9c.3,0,.58-.09.82-.25-.22-.37-.35-.8-.35-1.25V1.5c0-.46.13-.88.35-1.25-.24-.15-.52-.25-.82-.25h0ZM2.87,9.2V2.8h2.18c.64,0,1.72.09,2.54.88.75.72.89,1.64.89,2.33,0,.84-.21,1.59-.83,2.22-.51.51-1.36.97-2.46.97h-2.32ZM4.53,7.79h.34c.4,0,.97-.04,1.4-.46.21-.2.49-.6.49-1.32,0-.6-.18-1.1-.5-1.4-.39-.36-.91-.4-1.32-.4h-.4v3.58Z";

  return GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 12 12" },
    child: [
      {
        tag: "path",
        attr: {
          d: path,
        },
        child: [],
      },
    ],
  })(remaining);
};
