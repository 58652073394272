import {
  Bs0SquareFill as Icon0Fill,
  Bs1SquareFill as Icon1Fill,
  Bs2SquareFill as Icon2Fill,
  Bs3SquareFill as Icon3Fill,
  Bs4SquareFill as Icon4Fill,
  Bs5SquareFill as Icon5Fill,
  Bs6SquareFill as Icon6Fill,
  Bs7SquareFill as Icon7Fill,
  Bs8SquareFill as Icon8Fill,
  Bs9SquareFill as Icon9Fill,
  BsSquareFill as IconSquareFill,
  Bs0Square as Icon0,
  Bs1Square as Icon1,
  Bs2Square as Icon2,
  Bs3Square as Icon3,
  Bs4Square as Icon4,
  Bs5Square as Icon5,
  Bs6Square as Icon6,
  Bs7Square as Icon7,
  Bs8Square as Icon8,
  Bs9Square as Icon9,
  BsSquare as IconSquare,
} from "react-icons/bs";

export const IconEnumeratedSquare = ({
  count,
  filled = true,
  className,
}: {
  count: number;
  filled?: boolean;
  className?: string;
}) => {
  const IconComponent = (
    filled
      ? [
          Icon0Fill,
          Icon1Fill,
          Icon2Fill,
          Icon3Fill,
          Icon4Fill,
          Icon5Fill,
          Icon6Fill,
          Icon7Fill,
          Icon8Fill,
          Icon9Fill,
          IconSquareFill,
        ]
      : [
          Icon0,
          Icon1,
          Icon2,
          Icon3,
          Icon4,
          Icon5,
          Icon6,
          Icon7,
          Icon8,
          Icon9,
          IconSquare,
        ]
  )[count > 9 ? 10 : count < 0 ? 10 : count];
  return IconComponent ? <IconComponent className={className} /> : null;
};
