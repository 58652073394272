import React from "react";

interface IconHalftimeProps extends React.SVGProps<SVGSVGElement> {
  direction?: "left" | "right";
}

import { GenIcon } from "react-icons";

export const IconHalftime: React.FC<IconHalftimeProps> = ({
  direction,
  ...props
}) => {
  // start with vertical lines
  let child = [
    {
      tag: "path",
      attr: {
        d: "M4.9,1.7c-.3,0-.6.3-.6.6v7.5c0,.3.3.6.6.6s.6-.3.6-.6V2.2c0-.3-.3-.6-.6-.6Z",
      },
      child: [] as any[],
    },
    {
      tag: "path",
      attr: {
        d: "M7.7,2.7v-.5c0-.3-.3-.6-.6-.6s-.6.3-.6.6v7.5c0,.3.3.6.6.6s.6-.3.6-.6V2.7Z",
      },
      child: [] as any[],
    },
  ];
  // optionally show left arrow
  if (!direction || direction === "left") {
    child.push({
      tag: "path",
      attr: {
        d: "M3,4.9c.2-.2.2-.6,0-.8-.2-.2-.6-.2-.8,0l-1.5,1.5h0c-.2.2-.2.6,0,.8l1.5,1.5c.2.2.6.2.8,0s.2-.6,0-.8l-.5-.5h1.8v-1.1h-1.8l.5-.5Z",
      },
      child: [] as any[],
    });
  }
  // optionally show right arrow
  if (!direction || direction === "right") {
    child.push({
      tag: "path",
      attr: {
        d: "M9.5,6.6l-.5.5c-.2.2-.2.6,0,.8s.6.2.8,0l1.5-1.5c.2-.2.2-.6,0-.8,0,0,0,0,0,0l-1.5-1.5c-.2-.2-.6-.2-.8,0s-.2.6,0,.8l.5.5h-1.8v1.1h1.8Z",
      },
      child: [] as any[],
    });
  }
  return GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 12 12" },
    child,
  })(props);
};
