import { GenIcon } from "react-icons";

export const IconPossession = (props: any) =>
  GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 72 72" },
    child: [
      {
        tag: "path",
        attr: {
          d: "M33.5-20.5,49.2,41.1c.4-3.1,1.4-17.6,1.4-17.6-1.7,1-3.7,1.5-5.8,1.5s-1.9,0-2.9-.4-2.3-1.4-4.6-2.3l-12.3-5.1-4.2-7.3C29.5,4.8,31.3,2.5,30.5,1.2c-1.4-2.3-7.3-.5-13.9,3.2-.1,0-.2.1-.4.2-1.3-.4-2.8-.4-4.1.4-1.5.9-2.4,2.5-2.5,4.1-2.5,2.2-3.8,4.2-2.9,5.6.4.7,1.8.9,5.1-.4l5.4,9.3c.5.9,1.4,1.7,2.4,2.1l15.2,6.4c.3.1.6.2.9.3l-.6,7.6-10.6,4.5c-1.3.6-2.3,1.7-2.8,3l-5.8,17.6c-.9,2.6.6,5.4,3.2,6.3.5.2,1,.3,1.6.3,2.1,0,4.1-1.3,4.7-3.4l5.1-15.5,15.6-6.6c2.1-.9,2.7-2,3.1-5.1ZM42.6,21.7c4.8,1.2,9.6-1.8,10.7-6.6,1.2-4.8-1.8-9.5-6.6-10.7-4.8-1.2-9.6,1.8-10.7,6.6-1.2,4.8,1.8,9.5,6.6,10.7ZM61.4,55.9l-13.2-2.4-.3-4.8c-.2.1-.4.2-.6.3l-9.1,3.9.3,5.1c.1,2.3,1.8,4.2,4.1,4.6l17.1,3.1c.3,0,.6,0,.9,0,2.4,0,4.5-1.7,4.9-4.1.5-2.7-1.3-5.3-4-5.8ZM29.1,15.7l4.5,1.9c-1-2.3-1.2-4.8-.5-7.3.1-.6.3-1.1.5-1.7l-1.5-3.2c-1.4,1.6-3.6,3.3-6.1,4.9l3.1,5.4Z",
        },
        child: [],
      },
    ],
  })(props);
